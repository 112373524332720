import { ValidatorFn, Validators } from '@angular/forms';
import { FormValidators } from '@core/models/form-errors.models';
import { noSpacesRegex, passwordRegex } from './validation-patterns.constants';
import { FormErrorType } from '@core/constants/forms/form-errors.constants';

const nameSymbols = `[a-zá-úä-üA-ZÀ-ÚÄ-Ü'‘’-]`;
const nameStartSymbols = `[a-zá-úä-üA-ZÀ-ÚÄ-Ü]`;
const nameSymbolsAll = `[a-zá-úä-üA-ZÀ-ÚÄ-Ü'‘’-\\s]`;

export const nameValidators: ValidatorFn[] = [
  Validators.maxLength(150),
  FormValidators.pattern(/\S/, FormErrorType.NAME_ONLY_SPACES),
  FormValidators.pattern(new RegExp(`^${nameSymbolsAll}+$`), FormErrorType.NAME_CONTAIN_SYMBOLS),
  FormValidators.pattern(new RegExp(`^${nameStartSymbols}`), FormErrorType.NAME_START_SYMBOLS),
  FormValidators.pattern(new RegExp(`^${nameSymbols}+(\\s+${nameSymbols}+)+`), FormErrorType.NAME_TWO_WORDS),
  FormValidators.pattern(new RegExp(`${nameSymbols}$`), FormErrorType.NAME_END_SYMBOLS),
];

export const passwordValidators: ValidatorFn[] = [
  FormValidators.pattern(noSpacesRegex, FormErrorType.SPACES),
  FormValidators.pattern(passwordRegex, FormErrorType.PASSWORD_PATTERN),
];
